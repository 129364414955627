import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import themeOptions from "../../.brew-cache/theme.json"

// Switch based on the activeTheme
let theme = ""

switch (themeOptions.activeTheme) {
  case "Brew Core Theme":
  default:
    theme = require("./brew-core-theme").default
    break
}

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}
