/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require("react")
const { PersistGate } = require("redux-persist/integration/react")
const { persistor } = require("/src/app/store")

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)

exports.onServiceWorkerUpdateReady = () => window.location.reload()

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

function scrollToAnchor(location, mainNavHeight = 200) {
  if (location && location.hash) {
    window.scrollTo({ top: 0 })
    setTimeout(function() {
      const item = document.getElementById(`${location.hash.replace("#", "")}`)
      if (item) {
        window.scrollTo({
          top: item.getBoundingClientRect().top - mainNavHeight,
          behavior: "smooth",
        })
      }
    }, 750)
  }
  return true
}

exports.wrapPageElement = ({ element }) => {
  return (
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  )
}
