import Link from "../components/Link"
import { processNodes } from "html-react-parser"
import React from "react"

/**
 * General helper functions
 */

export const exampleHelper = () => {
  return null
}

export const transformLink = node => {
  // Convert a tags to links that handle routing
  if (node.type === "tag" && node.name === "a") {
    // Replace the admin URL in the link if set
    const link = node.attribs.href
    return (
      <Link key={link} to={link}>
        {processNodes(node.children, transformLink)}
      </Link>
    )
  }
}

export const transformLists = node => {
  // Convert a tags to links that handle routing
  if (node.type === "tag" && node.name === "a") {
    // Replace the admin URL in the link if set
    const link = node.attribs.href
    return (
      <Link key={link} to={link}>
        {processNodes(node.children, transformLists)}
      </Link>
    )
  }

  if (node.type === "tag" && node.name === "li") {
    return (
      <li>
        {node.content}
        {processNodes(node.children, transformLists)}
      </li>
    )
  }
}

export const isBrowser = typeof window !== `undefined`
