import { createSlice } from "@reduxjs/toolkit"

export const editionSlice = createSlice({
  name: "edition",
  initialState: "",
  reducers: {
    setEdition: (state, action) => {
      return action.payload
    },
    clear: () => {
      return ""
    },
  },
})

export const { setEdition } = editionSlice.actions
export default editionSlice.reducer
