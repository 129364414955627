import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { CookieStorage } from "redux-persist-cookie-storage"
import storage from "redux-persist/lib/storage"
import Cookies from "cookies-js"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"

import editionReducer from "./editionSlice"
import personaReducer from "./personaSlice"
import { isBrowser } from "../helpers"

const persistConfig = {
  key: "root",
  storage: isBrowser
    ? new CookieStorage(Cookies, {
        expiration: {
          default: 60 * 60 * 24 * 30,
        },
      })
    : storage,
  blacklist: ['persona'],
}

const rootReducer = combineReducers({
  edition: editionReducer,
  persona: personaReducer,
})
const persisterReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persisterReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
