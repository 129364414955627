// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-page-js": () => import("./../../../src/pages/preview-page.js" /* webpackChunkName: "component---src-pages-preview-page-js" */),
  "component---src-pages-preview-post-js": () => import("./../../../src/pages/preview-post.js" /* webpackChunkName: "component---src-pages-preview-post-js" */),
  "component---src-templates-edition-js": () => import("./../../../src/templates/edition.js" /* webpackChunkName: "component---src-templates-edition-js" */),
  "component---src-templates-edition-legacy-js": () => import("./../../../src/templates/editionLegacy.js" /* webpackChunkName: "component---src-templates-edition-legacy-js" */),
  "component---src-templates-list-posts-js": () => import("./../../../src/templates/list-posts.js" /* webpackChunkName: "component---src-templates-list-posts-js" */),
  "component---src-templates-magazine-js": () => import("./../../../src/templates/magazine.js" /* webpackChunkName: "component---src-templates-magazine-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

