import { createSlice } from "@reduxjs/toolkit"
import { isBrowser } from "../helpers"
import Cookies from "cookies-js"

const params = isBrowser && new URLSearchParams(window.location.search)

let initialState = { slug: "default", by: "default" };
if (isBrowser) {
    let personaParam = params.get("persona");
    let personaCookie = Cookies.get("persona")

    try {
        personaCookie = JSON.parse(personaCookie)
    } catch (e) {
        if (personaCookie) {
            personaCookie = { slug: personaCookie, by: "user" }
            Cookies.set("persona", JSON.stringify(personaCookie), { expires: 365 * 86400 })
        } else {
            personaCookie = false;
        }
    }

    if (personaParam) {
        if (personaCookie) {
            if (personaCookie.slug !== personaParam && personaCookie.by !== "user") {
                // The cookie persona is not the same as the url param persona
                // AND Cookie was not set explicitly by the user's choice
                initialState = { slug: personaParam, by: "url" };
                Cookies.set("persona", JSON.stringify(initialState), { expires: 365 * 86400 })
            } else {
                initialState = personaCookie
            }
        } else {
            // No cookie exists
            initialState = { slug: personaParam, by: "url" }
            if (typeof window.OptanonActiveGroups !== "undefined" && window.OptanonActiveGroups.search("C0003") === -1) {
                // User has consented to cookies already
                Cookies.set("persona", JSON.stringify(initialState), { expires: 365 * 86400 })
            }
        }
    } else if (personaCookie) {
        initialState = personaCookie
    }
}

export const personaSlice = createSlice({
    name: "persona",
    initialState,
    reducers: {
        setPersona: (state, action) => {
            state.slug = action.payload
            state.by = "user" 
        },
    },
})

export const { savePersonaCookie, setPersona } = personaSlice.actions
export default personaSlice.reducer